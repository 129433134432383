.pos {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__wrapper {
    flex-grow: 1;
  }

  &__actions {
    flex-grow: 0;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > div {
      width: 200px;
    }
  }
  &__table {
      height: 310px;
      overflow-y: scroll;

  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    > div:first-child {
      flex-grow: 1;
    }

    > div:last-child {
      height: 310px;
      overflow-y: scroll;
      flex-grow: 0;
    }
  }
}

.pos-button {
  border-radius: 15px;
  border: 0;
  background: white;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  display: block;
  width: 100%;
  cursor: pointer;
  font-size: 1.25rem;

  &:hover {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  }

  &__xsmall {
    height: 50px;
    border-radius: 15px;
    .ant-card-body {
      padding: 0 24px;
      line-height: 40px;
    }
  }

  &__small {
    height: 80px;
    .ant-card-body {
      line-height: 32px;
    }
  }

  // Sizes
  &__default {
    height: 120px;
    .ant-card-body {
      line-height: 72px;
    }
  }

  &__large {
    height: 150px;
  }

  &__color-red {
    background: linear-gradient(
      0deg,
      rgba(255, 163, 158, 1) 0%,
      rgba(255, 163, 158, 0.25) 100%
    );
  }
  &__color-volcano {
    background: linear-gradient(
      0deg,
      rgba(255, 187, 150, 1) 0%,
      rgba(255, 187, 150, 0.25) 100%
    );
  }
  &__color-orange {
    background: linear-gradient(
      0deg,
      rgba(255, 213, 145, 1) 0%,
      rgba(255, 213, 145, 0.25) 100%
    );
  }
  &__color-gold {
    background: linear-gradient(
      0deg,
      rgba(255, 229, 143, 1) 0%,
      rgba(255, 229, 143, 0.25) 100%
    );
  }
  &__color-yellow {
    background: linear-gradient(
      0deg,
      rgba(255, 251, 143, 1) 0%,
      rgba(255, 251, 143, 0.25) 100%
    );
  }
  &__color-lime {
    background: linear-gradient(
      0deg,
      rgba(234, 255, 143, 1) 0%,
      rgba(234, 255, 143, 0.25) 100%
    );
  }
  &__color-green {
    background: linear-gradient(
      0deg,
      rgba(183, 235, 143, 1) 0%,
      rgba(183, 235, 143, 0.25) 100%
    );
  }
  &__color-cyan {
    background: linear-gradient(
      0deg,
      rgba(135, 232, 222, 1) 0%,
      rgba(135, 232, 222, 0.25) 100%
    );
  }
  &__color-blue {
    background: linear-gradient(
      0deg,
      rgba(145, 213, 255, 1) 0%,
      rgba(145, 213, 255, 0.25) 100%
    );
  }
  &__color-purple {
    background: linear-gradient(
      0deg,
      rgba(211, 173, 247, 1) 0%,
      rgba(211, 173, 247, 0.25) 100%
    );
  }
  &__color-magenta {
    background: linear-gradient(
      0deg,
      rgba(255, 173, 210, 1) 0%,
      rgba(255, 173, 210, 0.25) 100%
    );
  }
  &__color-grey {
    pointer-events: none;
    background: linear-gradient(
      0deg,
      rgba(192,192,192,1) 0%,
      rgba(192,192,192,0.25) 100%
    );
  }
}

.webcam-image {
  border: 1px solid #ddd;
  background: #f5f5f5;
  height: 24px;
  width: 100%;
  cursor: pointer;

  &__default {
    width: 160px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 20px;
  }
}
