$duration: 1.8s;
$dimension: 8em;

@keyframes moveup {
  0%,
  60%,
  100% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(0);
  }
  25% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(1em);
  }
}

@keyframes movedown {
  0%,
  60%,
  100% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(0);
  }
  25% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(-1em);
  }
}

body {
  background: #f1f1f1;
}

.apploader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__layer {
    display: block;
    position: absolute;
    height: $dimension;
    width: $dimension;

    box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);

    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg);

    &:nth-of-type(1) {
      background: #534a47;
      margin-top: $dimension/2;
      animation: movedown $duration cubic-bezier(0.39, 0.575, 0.565, 1)
        $duration/2 infinite normal;
      &:before {
        content: '';
        position: absolute;
        width: 85%;
        height: 85%;
        background: #37332f;
      }
    }

    &:nth-of-type(2) {
      background: #5a96bc;
      margin-top: $dimension/4;
    }

    &:nth-of-type(3) {
      background: rgba(255, 255, 255, 0.6);
      animation: moveup $duration cubic-bezier(0.39, 0.575, 0.565, 1) infinite
        normal;
    }
  }
}
