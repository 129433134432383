@import '../node_modules/antd/dist/antd.min.css';

html {
  scroll-behavior: smooth;
  min-height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Segoe UI', Helvetica, -apple-system, BlinkMacSystemFont, Roboto,
    'Helvetica Neue', sans-serif;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  background-color: #f1f2f5;
}

.ant-layout-content {
  display: flex;
  flex-direction: column;
}

form {
  .form-group {
    padding-bottom: 1rem;
  }

  table {
    .form-group {
      padding-bottom: 0;
    }
  }

  .ant-input-number {
    width: 100%;
  }

  .ant-select {
    display: block;
  }
}

.ant-badge {
  white-space: nowrap;
}

.app-version {
  position: fixed;
  bottom: 0;
  right: 0;
  opacity: 0.5;
  padding: 0 12px;
}

.ant-btn-success {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
  &:active,
  &:focus {
    color: #fff;
    background: #52c41a;
    border-color: #52c41a;
  }

  &:hover {
    color: #fff;
    background: #73d13d;
    border-color: #73d13d;
  }
}

.ant-btn-warning {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
  &:active,
  &:focus {
    color: #fff;
    background: #faad14;
    border-color: #faad14;
  }

  &:hover {
    color: #fff;
    background: #ffc53d;
    border-color: #ffc53d;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.card-message {
  .ant-message-notice-content {
    padding: 3rem 8rem;
    font-size: 150%;
  }
}

.img-streched {
  // max-width: 15rem;
  // max-height: 5rem;

  border: 1px dotted lightgray;
  margin: 10px;
  display: block;
  // max-width:230px;
  max-height: 10rem;
  width: auto;
  height: auto;
}

.red .ant-table-cell {
  background-color: #f5d9d9 !important;
}

.app-wrapper {
  position: relative;
  z-index: 0;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.app-wrapper.loading {
  //filter: blur(3px); /* Optional: Add a blur effect to the app content when loading */
  //pointer-events: none; /* Optional: Disable pointer events on the app content when loading */
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.account-custom-input-number {
  .ant-input-number-group, .ant-input-number {
    height: 100%;
    font-size: 2rem;
  }
}